export default function Stack() {
  return (
    <>
      <section id="stack">
        <div className="stack__container">
          <div className="stack__row">
            <h1
              className="stack__title"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              My Stack
            </h1>
            <div className="stack__contents--container">
              <figure
                className="stack__content--wrapper"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/732/732212.png"
                  alt="HTML icon"
                  className="stack__img"
                />
                <h2 className="language__name html">HTML</h2>
              </figure>
              <figure
                className="stack__content--wrapper"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <img
                  src="https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/121-css3-512.png"
                  alt="CSS icon"
                  className="stack__img"
                />
                <h2 className="language__name css">CSS</h2>
              </figure>
              <figure
                className="stack__content--wrapper"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png"
                  alt="JavaScipt icon"
                  className="stack__img"
                />
                <h2 className="language__name">
                  J<span className="javascript">ava</span>S
                  <span className="javascript">cript</span>
                </h2>
              </figure>
              <figure
                className="stack__content--wrapper"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <img
                  src="https://eportfolio-psi.vercel.app/tech/React-icon.svg.png"
                  alt="React icon"
                  className="stack__img"
                />
                <h2 className="language__name react">React.js</h2>
              </figure>
              <figure
                className="stack__content--wrapper"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/2048px-Typescript_logo_2020.svg.png"
                  alt="Typescript icon"
                  className="stack__img"
                />
                <h2 className="language__name white">
                  T<span className="typescript--alt">ype</span>S
                  <span className="typescript--alt">cript</span>
                </h2>
              </figure>
              <figure
                className="stack__content--wrapper"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <img
                  src="https://eportfolio-psi.vercel.app/tech/redux.png"
                  alt="Redux icon"
                  className="stack__img"
                />
                <h2 className="language__name redux">Redux</h2>
              </figure>
              <figure
                className="stack__content--wrapper"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <img
                  src="https://cdn4.iconfinder.com/data/icons/google-i-o-2016/512/google_firebase-2-512.png"
                  alt="Firebase icon"
                  className="stack__img"
                />
                <h2 className="language__name firebase">
                  <span className="firebase--med">Fire</span>base
                </h2>
              </figure>
              <figure
                className="stack__content--wrapper"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <img
                  src="	https://static-00.iconduck.com/assets.00/next-js-icon-512x512-zuauazrk.png"
                  alt="Next icon"
                  className="stack__img"
                />
                <h2 className="language__name">Next.js</h2>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
