import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

export default function Contact() {
  const [displaySubmit, setDisplaySubmit] = useState(true);
  const [displayLoading, setDisplayLoading] = useState(false);
  const [displaySent, setDisplaySent] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    setDisplaySubmit(false)
    setDisplayLoading(true);

    emailjs
      .sendForm("service_d7sdf3u", "template_ujds9kn", form.current, {
        publicKey: "6bb0lY29GPPPQglEn",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setDisplayLoading(false);
          setDisplaySent(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <>
      <div id="contact-section" />
      <section id="contact">
        <h1
          className="contact__title"
          data-aos="fade-right"
          data-aos-anchor-placement="bottom-bottom"
        >
          Ready to Connect?
        </h1>
        <h2
          className="contact__desc"
          data-aos="fade-right"
          data-aos-anchor-placement="bottom-bottom"
        >
          I am currently seeking job opportunities and any related inquiries
          that will provide me with significant experience.
          <br />
          You can use the provided form below with the corresponding details so
          that I can connect with you.
        </h2>
        <form
          id="contact__form"
          ref={form}
          onSubmit={sendEmail}
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <span className="form__item">
            <input type="text" name="user_email" className="input" required />
            <label className="form__item--label">
              <span className="form__text--top-position form__text--transition">
                E
              </span>
              <span className="form__text--top-position form__text--transition-1">
                m
              </span>
              <span className="form__text--top-position form__text--transition-2">
                a
              </span>
              <span className="form__text--top-position form__text--transition-3">
                i
              </span>
              <span className="form__text--top-position form__text--transition-4">
                l
              </span>
              <p className="form__item--text">Email</p>
            </label>
          </span>
          <span className="form__item">
            <input type="text" name="user_title" className="input" required />
            <label className="form__item--label">
              <span className="form__text--top-position form__text--transition">
                T
              </span>
              <span className="form__text--top-position form__text--transition-1">
                i
              </span>
              <span className="form__text--top-position form__text--transition-2 title__t--left">
                t
              </span>
              <span className="form__text--top-position form__text--transition-3 title__l--left">
                l
              </span>
              <span className="form__text--top-position form__text--transition-4 title__e--left">
                e
              </span>
              <p className="form__item--text">Title</p>
            </label>
          </span>
          <div className="form__item">
            <textarea
              type="text"
              name="message"
              className="input textarea"
              required
            />
            <label className="form__item--label textarea-display">
              <span className="form__text--top-position form__text--transition message__m--left">
                M
              </span>
              <span className="form__text--top-position form__text--transition-1 message__e--left">
                e
              </span>
              <span className="form__text--top-position form__text--transition-2 message__s--left">
                s
              </span>
              <span className="form__text--top-position form__text--transition-3 message__s--left-1">
                s
              </span>
              <span className="form__text--top-position form__text--transition-4 message__a--left">
                a
              </span>
              <span className="form__text--top-position form__text--transition-5 message__g--left">
                g
              </span>
              <span className="form__text--top-position form__text--transition-6 message__e--left-1">
                e
              </span>
              <p className="form__item--text textarea-display">Message</p>
            </label>
          </div>
          <div className="submit__wrapper">
            <button id="form__submit" disabled={displayLoading || displaySent}>
              {displaySubmit && (
                <input
                  type="submit"
                  value="Submit"
                  className="form__submit--input"
                />
              )}
              {displayLoading && (
                <div className="form__submit--loading">
                  <div className="loader-spanne-20">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
              {displaySent && (
                <span className="form__submit--sent">Message Sent!</span>
              )}
            </button>
          </div>
        </form>
      </section>
    </>
  );
}
