import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaGithub, FaLink } from "react-icons/fa";

export default function Projects({ projects }) {
  var settings = {
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="projects">
      <h1
        className="projects__title"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
      >
        Projects
      </h1>
      <ul className="projects__list">
        {projects.map((project) => (
          <li
            className="project"
            key={project.id}
            data-aos="slide-up"
            data-aos-anchor-placement="bottom-bottom"
          >
            <div className="project__details">
              <h2 className="project__title white">{project.projectTitle}</h2>
              <hr />
              <h3 className="project__desc white">{project.description}</h3>
              <h3 className="project__tech white">
                Technology used: {project.tech}
              </h3>
            </div>

            <div className="project__visuals">
              <Slider {...settings}>
                {project.images.map((image, index) => (
                  <figure className="project__carousel" key={index}>
                    <img
                      src={image[`image${index + 1}`]}
                      alt={`rawr${index + 1}`}
                      className="project__images"
                    />
                  </figure>
                ))}
              </Slider>

              <div className="project__links">
                <a
                  href={project.webLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="project__link"
                >
                  <FaLink color="white" size={21} />
                </a>

                <a
                  href={project.githubLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="project__link"
                >
                  <FaGithub color="white" size={21} />
                </a>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}
