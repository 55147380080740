import "./App.css";
import "./fonts.css";
import Landing from "./components/Landing";
import Projects from "./components/Projects";
import Stack from "./components/Stack";
import FloatingContact from "./components/subcomponents/FloatingContact";
import { projects } from "./data";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);
  
  return (
    <>
      <HelmetProvider>

      <Helmet>
      <title>Laurence Lama</title>
      <meta name="description" content="This is Laurence, a freelance frontend developer specializing in React, Next, and modern web technologies. 
      Let's transform your vision into responsive websites!" />
      <meta name="keywords" content="Laurence, Laurence Lama, Freelancer, Freelance frontend developer, Freelance developer, Web developer, Web development" />
      </Helmet>

      <Landing />
      
      <main>
      <FloatingContact />
      <Stack />
      <Projects projects={projects} />
      <Contact />
      </main>

      <Footer />

      </HelmetProvider>
    </>
  );
}

export default App;
