import { useState } from "react";
import "../App.css";
import { FaFilePdf, FaGithub, FaLinkedin } from "react-icons/fa";
import Menu from "./subcomponents/Menu";
import resume from '../assets/LAMA_Resume.pdf'

const Landing = () => {
  const [ripples, setRipples] = useState([]);
  const [sizeMultiplier] = useState(0.5);

  const [menuStatus, setMenuStatus] = useState(false);

  const handleClick = () => {
    setMenuStatus((prevMenuStatus) => !prevMenuStatus);
  };

  const handleInteraction = (e) => {
    const rippleContainer = e.currentTarget;
    const rect = rippleContainer.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);

    const x = e.clientX - rect.left - size / 4;
    const y = e.clientY - rect.top - size / 4;

    const newRipple = {
      id: Date.now(),
      x,
      y,
      size: size * sizeMultiplier,
    };

    setRipples([...ripples, newRipple]);
  };

  return (
    <>
      <header
        id="landing"
        onMouseMove={handleInteraction}
        onClick={handleInteraction}
      >
        {ripples.map((ripple) => (
          <span
            key={ripple.id}
            className="water-ripple"
            style={{
              top: ripple.y + "px",
              left: ripple.x + "px",
              width: ripple.size + "px",
              height: ripple.size + "px",
            }}
          />
        ))}

        <div className="landing__row">
          <label
            className="menu-icon"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-anchor-placement="top-bottom"
          >
            <input type="checkbox" />
            <svg viewBox="0 0 32 32" onClick={handleClick}>
              <path
                className="line line-top-bottom"
                d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
              ></path>
              <path className="line" d="M7 16 27 16"></path>
            </svg>
          </label>

          <Menu menuStatus={menuStatus} />

          <div className="landing__top-content">
            <h1
              className="landing__name"
              data-aos="fade-up"
              data-aos-delay="1200"
              data-aos-anchor-placement="top-bottom"
            >
              Laurence{" "}
              <span className="landing__name--mobile-display">Serge P.</span>{" "}
              Lama
            </h1>
            <a
              href="#contact-section"
              className="landing__contact"
              data-aos="fade-up"
              data-aos-delay="1400"
              data-aos-anchor-placement="top-bottom"
            >
              Contact Me
            </a>
          </div>

          <div className="landing__bottom-content">
            <div
              className="landing__role"
              data-aos="fade-right"
              data-aos-duration="6000"
              data-aos-easing="ease-in"
              data-aos-delay="2000"
              data-aos-anchor-placement="top-bottom"
            >
              FREELANCE
              <br />
              FRONTEND ENGINEER
            </div>

            <div
              className="landing__social"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <a
                className="landing__social--links github"
                href="https://github.com/LaurenceLama"
                rel="noreferrer"
                target="_blank"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-anchor-placement="top-bottom"
              >
                <span className="social-icons--container">
                  <FaGithub color="black" size={21} />
                </span>
                <span className="social--background" />
              </a>

              <a
                className="landing__social--links linkedin"
                href="https://www.linkedin.com/in/laurencelama/"
                rel="noreferrer"
                target="_blank"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-anchor-placement="top-bottom"
              >
                <span className="social-icons--container">
                  <FaLinkedin color="white" size={21} />
                </span>
                <span className="social--background"></span>
              </a>

              <a
                className="landing__social--links resume"
                href={resume}
                rel="noreferrer"
                target="_blank"
                data-aos="fade-up"
                data-aos-delay="800"
                data-aos-anchor-placement="top-bottom"
              >
                <span className="social-icons--container">
                  <FaFilePdf color="white" size={21} />
                </span>
                <span className="social--background"></span>
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Landing;
