import landingSumm from "./assets/landSumm.png";
import foryou from "./assets/foryouSumm.png";
import planSumm from "./assets/planSumm.png";
import player from "./assets/playerSumm.png";
import landingNft from "./assets/landingNFT.png";
import explore from "./assets/exploreNFT.png";
import nftNft from "./assets/nftNFT.png";
import author from "./assets/authorNFT.png";
import landingNetflix from "./assets/landingNetflix.png";
import planNetflix from "./assets/planNetflix.png";
import home from "./assets/homeNetflix.png";
import movie from "./assets/movieNetflix.png";
import landingTweet from "./assets/landingTweet.png";
import modalTweet from "./assets/signInTweet.png";
import commentTweet from "./assets/commentTweet.png";
import threadTweet from "./assets/threadTweet.png";

export const projects = [
  {
    id: 1,
    projectTitle: "Summarist Library",
    description:
      "A library where users can read and/or listen with its audio playback feature. It also contains numerous methods of signing up, subscription process by utlizing stripe payment, and other relative functionalites.",
    tech: "NextJS, Typescript, Firebase, Stripe, Redux",
    images: [
      { image1: landingSumm },
      { image2: foryou },
      { image3: planSumm },
      { image4: player },
    ],
    webLink: "https://advanced-internship-ten.vercel.app",
    githubLink: "https://github.com/LaurenceLama/advanced-internship",
  },
  {
    id: 2,
    projectTitle: "NFT Marketplace",
    description:
      "A dynamic and immersive web application that involves various features such as real-time data integration, diverse user paths, carousel functionality, and related features for enhanced user interface.",
    tech: "HTML, CSS, JavaScript, React",
    images: [
      { image1: landingNft },
      { image2: explore },
      { image3: nftNft },
      { image4: author },
    ],
    webLink: "https://lama-internship.vercel.app",
    githubLink: "https://github.com/LaurenceLama/lama-internship",
  },
  {
    id: 3,
    projectTitle: "Netflix Clone",
    description:
      "Imitating one of the popular sites - this netflix clone holds some of the features that can be observed from the actual software. It includes payment verification, my-list function, a pop-up box for displaying information and video player, etc.",
    tech: "NextJS, Typescript, Firebase, Stripe, Tailwind CSS",
    images: [
      { image1: landingNetflix },
      { image2: planNetflix },
      { image3: home },
      { image4: movie },
    ],
    webLink: "https://netflix-clone-seven-kohl.vercel.app",
    githubLink: "https://github.com/LaurenceLama/netflix-clone",
  },
  {
    id: 4,
    projectTitle: "Twitter Clone",
    description:
      "A tuned replica of the popular social media platform. This iteration encompasses key features such as tweet functionality, a chronological feed, and a responsive design, delivering an authentic Twitter experience",
    tech: "NextJS, React, Firebase, Stripe, Redux, Tailwind CSS",
    images: [
      { image1: landingTweet },
      { image2: modalTweet },
      { image3: commentTweet },
      { image4: threadTweet },
    ],
    webLink: "https://twitter-clone-more-stack.vercel.app/",
    githubLink: "https://github.com/LaurenceLama/twitter-clone_more-stack",
  },
];