import { FaFilePdf, FaGithub, FaLinkedin } from "react-icons/fa";
import logo from "../assets/logo.png";
import resume from "../assets/LAMA_Resume.pdf";

export default function Footer() {
  return (
    <>
      <footer id="footer">
        <div className="footer__row">
          <a
            href="#landing"
            className="footer-btn"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <div className="footer-btn--display">
              <figure className="my-logo">
                <img src={logo} alt="" className="my-logo--image" />
              </figure>
            </div>
            <div className="footer-btn--hover">
              <h2 className="footer-btn--text white">To top</h2>
            </div>
          </a>
        </div>

        <div
          className="footer__social"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <a
            className="landing__social--links github"
            href="https://github.com/LaurenceLama"
            rel="noreferrer"
            target="_blank"
          >
            <span className="social-icons--container">
              <FaGithub color="black" size={21} />
            </span>
            <span className="social--background" />
          </a>

          <a
            className="landing__social--links linkedin"
            href="https://www.linkedin.com/in/laurencelama/"
            rel="noreferrer"
            target="_blank"
          >
            <span className="social-icons--container">
              <FaLinkedin color="white" size={21} />
            </span>
            <span className="social--background"></span>
          </a>

          <a
            className="landing__social--links resume"
            href={resume}
            rel="noreferrer"
            target="_blank"
          >
            <span className="social-icons--container">
              <FaFilePdf color="white" size={21} />
            </span>
            <span className="social--background"></span>
          </a>
        </div>

        <h2
          className="footer__copyright white"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          © 2024 Laurence Lama
        </h2>
      </footer>
    </>
  );
}
