export default function Menu({ menuStatus }) {
  return (
    <>
      <nav className={`menu ${menuStatus ? "open" : ""} `}>
        <a
          href="#stack"
          className="menu__content menu__stack link__hover-effect"
        >
          Stack
        </a>
        <a
          href="#projects"
          className="menu__content menu__projects link__hover-effect"
        >
          Projects
        </a>
        <a
          href="#contact"
          className="menu__content menu__contact link__hover-effect"
        >
          Contact
        </a>
      </nav>
    </>
  );
}
